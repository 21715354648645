import Indicator from 'components/indicator'
import Table from 'components/Table'
import { reportingClinicianPatient, reportingClinicianPatientData } from 'mocks/table'
import React from 'react'
import styles from 'pages/Admin/index.module.scss';

const PatientsTable = () => {
  return (
    <Table
      headers={reportingClinicianPatient}
      tableData={reportingClinicianPatientData}
    >
      {(row) => {
        return(
          <>
            <td>{row.name}</td>
            <td>{row.email}</td>
            <td>{row.created_at}</td>
            <td>{row. centre}</td>
            <td>
              <div className={styles.indicatorWrapper}>
                <Indicator isActive={row?.status} />
                <span>{row?.status === "awaiting_report" ? "Awaiting Report" : row?.status}</span>
              </div>
            </td>

          </>
        )
      }}
    </Table>
  )
}

export default PatientsTable