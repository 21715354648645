export const headers = [
  {
    text: 'Package Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Partnership Type',
    alignment: 'center',
    sortable: false,
    key: 'partnership'
  },
  {
    text: 'Package Type',
    alignment: 'center',
    sortable: false,
    key: 'package'
  },
  {
    text: 'Status',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const data = [
  {
    name: 'Union Staff Package',
    partnership: 'Corporate Partnership',
    package: 'Pre-employment',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'Enyata Staff Package',
    partnership: 'Business',
    package: 'Annual screening',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'Enyata Staff Package',
    partnership: 'Corporate Partnership',
    package: 'Pre-employment',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'GTB Staff Package',
    partnership: 'Corporate Partnership',
    package: 'Pre-employment',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'Orange Academy Package',
    partnership: 'Corporate Partnership',
    package: 'Annual screening',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'Enyata Staff Package',
    partnership: 'Corporate Partnership',
    package: 'Annual screening',
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    name: 'Union Staff Package',
    partnership: 'Business',
    package: 'Annual screening',
    status: 'active',
    date: 'May 27, 2022'
  }
];

export const testHeaders = [
  {
    text: 'S/N',
    alignment: 'center',
    sortable: false,
    key: 'id'
  },
  {
    text: 'Test Name',
    alignment: 'center',
    sortable: false,
    key: 'test_name'
  },
  {
    text: 'Regular',
    alignment: 'center',
    sortable: false,
    key: 'regular'
  },
  {
    text: 'Lab',
    alignment: 'center',
    sortable: false,
    key: 'lab'
  },
  {
    text: 'TBH',
    alignment: 'center',
    sortable: false,
    key: 'tbh'
  }
];

export const packageHeaders = [
  {
    text: 'S/N',
    alignment: 'center',
    sortable: false,
    key: 'id'
  },
  {
    text: 'Test Name',
    alignment: 'center',
    sortable: false,
    key: 'test_name'
  }
];

export const packageData = [
  {
    id: 1,
    category: 'Clinical chemistry',
    tests: [
      {
        id: 'SS01',
        name: 'AAFB'
      },
      {
        id: 'SS02',
        name: 'SSO2'
      },
      {
        id: 'SS03',
        name: 'CA-15-3'
      }
    ],
    show: false
  },
  {
    id: 2,
    category: 'Microbiology',
    show: false,
    tests: [
      {
        id: 'SS01',
        name: 'AAFB'
      },
      {
        id: 'SS02',
        name: 'SSO2'
      }
    ]
  },
  {
    id: 3,
    category: 'Haematology',
    show: false,
    tests: [
      {
        id: 'SS01',
        name: 'AAFB'
      }
    ]
  }
];

export const testData = [
  {
    id: 1,
    category: 'Clinical chemistry',
    tests: [
      {
        id: 'SS01',
        name: 'AAFB',
        regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      },
      {
        id: 'SS02',
        name: 'SSO2',
        regular: 'NGN30,000',
        lab: 'NGN25,000',
        tbh: 'NGN5,000'
      },
      {
        id: 'SS03',
        name: 'CA-15-3',
        regular: 'NGN30,000',
        lab: 'NGN25,000',
        tbh: 'NGN5,000'
      }
    ],
    show: false
  },
  {
    id: 2,
    category: 'Microbiology',
    show: false,
    tests: [
      {
        id: 'SS01',
        name: 'AAFB',
        // regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      },
      {
        id: 'SS02',
        name: 'SSO2',
        regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      }
    ]
  },
  {
    id: 3,
    category: 'Haematology',
    show: false,
    tests: [
      {
        id: 'SS01',
        name: 'AAFB',
        regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      }
    ]
  }
];

export const mocktestData = [
  {
    id: 1,
    category: 'Clinical chemistry',
    tests: [
      {
        id: 'SS01',
        name: 'AAFB',
        regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      },
      {
        id: 'SS02',
        name: 'SSO2',
        regular: 'NGN30,000',
        lab: 'NGN25,000',
        tbh: 'NGN5,000'
      },
      {
        id: 'SS03',
        name: 'CA-15-3',
        regular: 'NGN30,000',
        lab: 'NGN25,000',
        tbh: 'NGN5,000'
      }
    ],
    show: true
  },
  {
    id: 2,
    category: 'Microbiology',
    show: true,
    tests: [
      {
        id: 'SS01',
        name: 'AAFB',
        // regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      },
      {
        id: 'SS02',
        name: 'SSO2',
        regular: 'NGN30,000',
        lab: 'NGN88,000',
        tbh: 'NGN3,000'
      }
    ]
  }
];

export const packageTypes = [
  {
    label: 'Annual Screening',
    value: 'Annual Screening'
  },
  {
    label: 'Pre-employment',
    value: 'Pre-employment'
  },
  {
    label: 'Pre-Insurance Screening',
    value: 'Pre-Insurance Screening'
  },
  {
    label: 'Food Handlers Screening',
    value: 'Food Handlers Screening'
  },
  {
    label: 'Domestic Staff Screening',
    value: 'Domestic Staff Screening'
  },
  {
    label: 'Sexual Health Screening',
    value: 'Sexual Health Screening'
  },
  {
    label: 'Fit for Work Screening',
    value: 'Fit for Work Screening'
  },
  {
    label: 'Self Pay',
    value: 'Self Pay'
  },
  {
    label: 'Website Packages',
    value: 'Website Packages'
  },
  {
    label: 'Pre-medical Screening',
    value: 'Pre-medical Screening'
  }
];
export const packageTypesWithoutSelfPay = [
  {
    label: 'Annual Screening',
    value: 'Annual Screening'
  },
  {
    label: 'Pre-employment',
    value: 'Pre-employment'
  },
  {
    label: 'Pre-Insurance Screening',
    value: 'Pre-Insurance Screening'
  },
  {
    label: 'Food Handlers Screening',
    value: 'Food Handlers Screening'
  },
  {
    label: 'Domestic Staff Screening',
    value: 'Domestic Staff Screening'
  },
  {
    label: 'Sexual Health Screening',
    value: 'Sexual Health Screening'
  },
  {
    label: 'Fit for Work Screening',
    value: 'Fit for Work Screening'
  },
  {
    label: 'Pre-medical Screening',
    value: 'Pre-medical Screening'
  }
];

export const partnershipTypes = [
  {
    name: 'Hospital',
    id: 'Hospital'
  },
  {
    name: 'Business',
    id: 'Business'
  },
  {
    name: 'HMO',
    id: 'HMO'
  }
];

export const superPackageHeader = [
  {
    text: 'Super Package Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Partnership Type',
    alignment: 'center',
    sortable: false,
    key: 'partnership'
  },
  {
    text: 'Paying Partner',
    alignment: 'center',
    sortable: false,
    key: 'package'
  },
  {
    text: 'Status',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const superPackageData = [
  {
    id: 1,
    name: 'Union Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 2,
    name: 'Enyata Staff Package',
    partnership_type: 'Business',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 3,
    name: 'Enyata Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 4,
    name: 'GTB Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 5,
    name: 'Orange Academy Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 6,
    name: 'Enyata Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 7,
    name: 'Super Package Name',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 8,
    name: 'Union Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 9,
    name: 'Union Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 10,
    name: 'Union Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    id: 11,
    name: 'Union Staff Package',
    partnership_type: 'Corporate Partnership',
    paying_partner: 'AXA Insurance',
    status: 'active',
    created_at: 'May 27, 2022'
  }
];

export const superPackageHeaderDetails = [
  {
    text: 'Package Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Package Type',
    alignment: 'center',
    sortable: false,
    key: 'package'
  },
  {
    text: 'Status',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const superPackageDetails = [
  {
    name: 'GTB Screening Male',
    package_type: 'Annual Screening',
    status: 'active',
    created_at: 'May 27, 2022'
  },
  {
    name: 'GTB Screening Female',
    package_type: 'Pre-employment',
    status: 'active',
    created_at: 'May 27, 2022'
  }
];
