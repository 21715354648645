import DashboardLayout from 'container/layouts/dashboard'
import React, { useState } from 'react'
import Back from 'assets/icons/back-icon.svg';
import styles from './reportingClinician.module.scss';
import Dropdown from 'components/Dropdown/Dropdown';
import { FaEllipsisVertical } from "react-icons/fa6";
import { Option } from 'components/Dropdown/Option';
import Indicator from 'components/indicator';
import PatientsTable from './PatientsTable';
import Pagination from 'components/Pagination/Pagination';
import SearchBox from 'components/SearchBox/SearchBox';
import DatePickerInput from 'components/FormInput/DatePickerInput';
import { useNavigate } from 'react-router-dom';

const ClinicianInfo = ({ title, caption, status }) => {
    return(
        <div className={styles.clinicanInfoContainer__details}>
            <h5>{title}</h5>
            {!status ? <p>{caption}</p> : 
            <div className={styles.clinicanInfoContainer__details__status}>
                <div  className={styles.clinicanInfoContainer__details__indicator}>
                    <Indicator isActive={status} />
                    <span>{status}</span>
                  </div>
              <Dropdown content={
                <>
                  <Option>Deactivate</Option>
                </>
              }>
                <div>
                  <FaEllipsisVertical style={{ 
                    fontSize: '14px',
                    color: 'white',
                    marginTop: '5px'
                  }} />
                </div>
              </Dropdown>
            </div>}
        </div>
    )

}

const ReportingClinicianDetail = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate()
  return (
    <DashboardLayout>
        <div>
            <div className={styles.reportClinicianContainer__heading}>
                <img src={Back} onClick={() => navigate(-1)} />
                <h2>Clinician Details</h2>
            </div>
            <div className={styles.clinicanInfoContainer}>
              <ClinicianInfo title="Clinician Name" caption="Dr. Fejiro Oghenetega" />
              <ClinicianInfo title="Email Address" caption="drfej@mdaasnigeria.com" /> 
              <ClinicianInfo title="Phone no." caption="+234 703 3218 457"  />
              <ClinicianInfo title="Status" status="active" />
              <ClinicianInfo title="Date Created" caption="May 27, 2022" />
              <ClinicianInfo title="Specialty" caption="Cardiologist" />
            </div>
            <div className={styles.patientDetails}>
                <h2 className={styles.patientDetails__heading}>Patient Details</h2>
                <div className={styles.patientDetails__actionBox}>
                    <SearchBox placeholder="Search by name" />
                    <div className={styles.patientDetails__date}>
                         <DatePickerInput
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(date) => {}}
                          placeholderText="From:"
                        />
                        <DatePickerInput
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate} 
                          onChange={(date) => {}}
                          placeholderText="To:"
                        />
                    </div>
                </div>
                <div>
                    <PatientsTable />
                    <Pagination />
                </div>
            </div>
        </div>
    </DashboardLayout>
  )
}

export default ReportingClinicianDetail